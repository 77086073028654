export const experiences = [
  {
    company: "Cisco",
    designation: "Software Engineer",
    startDate: "April 2019",
    endDate: "Present",
    companyLogo:
      "https://res.cloudinary.com/dk61hw3vd/image/upload/v1629886945/cisco-logo_tf48ge.png",
    responsibilities: [
      "Working on UI of multiple features for both Web and Mobile app of a cloud based platform that makes IT operations management smatter and simpler",
      "Collaborating with management and ux team for brainstorming to smoother delivery of feature",
    ],
  },
  {
    company: "HARMAN",
    designation: "UI Developer",
    startDate: "January 2017",
    endDate: "April 2019",
    companyLogo:
      "https://res.cloudinary.com/dk61hw3vd/image/upload/v1629886945/harman-logo_gbly5z.png",
    responsibilities: [
      "Developed frontend for Car Controller, Driver Skill analysis modules using Angular and Redux.",
      "Developed frontend for a Travel Planner app using Angular and Redux",
      "Worked with different teams on multiple POC using Angular, React and Redux.",
    ],
  },
  {
    company: "EI DESIGN PVT LTD",
    designation: "Web Developer",
    startDate: "August 2015",
    endDate: "January 2017",
    companyLogo:
      "https://res.cloudinary.com/dk61hw3vd/image/upload/v1629886946/eidesign-logo_plgvid.png",
    responsibilities: [
      "Created a Bug Tracker tool for internal bug tracking and for integration with e learning content for users to report bugs using PHP, HTML5 and Angular",
      "Developed an HTML5 course builder tool to create new courses using existing templates using PHP, HTML5 and Angular.",
    ],
  },
  {
    company: "Emart Solutions",
    designation: "Web Developer",
    startDate: "February 2015 ",
    endDate: "July 2015 ",
    companyLogo:
      "https://res.cloudinary.com/dk61hw3vd/image/upload/v1629886945/emart-logo_gavaey.jpg",
    responsibilities: [
      "Worked on multiple feature enhancement and redesign of eCommerce application on both Frontend and Backend",
      "Worked on Consumer Loyalty application enhancement for Frontend and defect fixes using PHP and Angular",
    ],
  },
  {
    company: "ADROITTE",
    designation: "​Junior Web Developer",
    startDate: "November 2013",
    endDate: "January 2015",
    companyLogo:
      "https://res.cloudinary.com/dk61hw3vd/image/upload/v1629886945/adroitte-logo_jqb7qz.jpg",
    responsibilities: [
      "Developed websites from initial design through completion, optimising all cross-browser compatibility",
      "Maintained both corporate and client websites, using scripting languages and content management systems including WordPress",
    ],
  },
];
