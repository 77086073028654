import classes from "./web.module.css";

const WebMenu = () => {
  return (
    <div className={classes.web}>
      <div className={classes.webOption}>
        <a href="#experience">
          <span className={classes.optionIcon}>
            <i class="fas fa-briefcase"></i>
          </span>
          <span>Experience</span>
        </a>
      </div>
      <div className={classes.webOption}>
        <a href="#skills">
          <span className={classes.optionIcon}>
            <i class="fas fa-laptop"></i>
          </span>
          <span>Skills</span>
        </a>
      </div>
      <div className={classes.webOption}>
        <a href="#contact">
          <span className={classes.optionIcon}>
            <i class="far fa-envelope"></i>
          </span>
          <span>Contact</span>
        </a>
      </div>
    </div>
  );
};

export default WebMenu;
