import { Fragment } from "react";
import Background from "../background";
import Body from "../body";
import About from "../body/about";
import Footer from "../footer";
import Header from "../header";
import classes from "./home.module.css";

const Home = () => {
  return (
    <Fragment>
      <Background>
        <Header />
        <section id="about">
          <About />
        </section>
      </Background>
      <div className={classes.home}>
        <Body />
        <Footer />
      </div>
    </Fragment>
  );
};

export default Home;
