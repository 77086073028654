import classes from "./about.module.css";

const About = () => {
  return (
    <div className={classes.about}>
      <div className={classes.info}>
        Hi There 👋, I am
        <br />
        <span className={classes.infoName}>Debasish</span>
        <br />I love experimenting with the web.
      </div>
      <div className={classes.photo}>
        <img
          src="https://res.cloudinary.com/dk61hw3vd/image/upload/v1629887892/coding_sptatr.png"
          className={classes.picture}
          alt="Debasish Pothal"
        />
      </div>
    </div>
  );
};

export default About;
