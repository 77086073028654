export const skills = [
  {
    name: "Javascript",
    icon: <i className="devicon-javascript-plain colored"></i>,
  },
  {
    name: "Typescript",
    icon: <i className="devicon-typescript-plain colored"></i>,
  },
  {
    name: "Angular",
    icon: <i className="devicon-angularjs-plain colored"></i>,
  },
  { name: "React", icon: <i className="devicon-react-original colored"></i> },
  { name: "Redux", icon: <i className="devicon-redux-original colored"></i> },
  { name: "Next", icon: <i className="devicon-nextjs-original colored"></i> },
  { name: "Node", icon: <i className="devicon-nodejs-plain colored"></i> },
  {
    name: "Express",
    icon: <i className="devicon-express-original colored"></i>,
  },
  { name: "HTML5", icon: <i className="devicon-html5-plain colored"></i> },
  { name: "CSS3", icon: <i className="devicon-css3-plain colored"></i> },
  { name: "Sass", icon: <i className="devicon-sass-original colored"></i> },
  {
    name: "Bootstrap",
    icon: <i className="devicon-bootstrap-plain colored"></i>,
  },
  { name: "Webpack", icon: <i className="devicon-webpack-plain colored"></i> },
  { name: "Git", icon: <i className="devicon-git-plain colored"></i> },
];
