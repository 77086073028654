import { useState } from "react";
import classes from "./header.module.css";
import MobileMenu from "./mobile";
import WebMenu from "./web";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.header}>
      <div className={classes.logo}>Debasish Pothal</div>
      <div className={classes.menu}>
        <div className={classes.webMenu}>
          <WebMenu />
        </div>
        <div className={classes.mobileMenu}>
          <div className={classes.menuIcon} onClick={() => setIsOpen(true)}>
            <i class="fas fa-ellipsis-h"></i>
          </div>
          {isOpen && <MobileMenu setIsOpen={setIsOpen} />}
        </div>
      </div>
    </div>
  );
};

export default Header;
