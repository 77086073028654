import classes from "./background.module.css";

const Background = ({ children }) => {
  return (
    <div className={classes.waveContainer}>
      <div className={classes.dataContainer}>{children}</div>
      <div className={`${classes.line} ${classes.line1}`}>
        <div className={`${classes.wave} ${classes.wave1}`}></div>
      </div>

      <div className={`${classes.line} ${classes.line2}`}>
        <div className={`${classes.wave} ${classes.wave2}`}></div>
      </div>

      <div className={`${classes.line} ${classes.line3}`}>
        <div className={`${classes.wave} ${classes.wave3}`}></div>
      </div>
    </div>
  );
};

export default Background;
