import classes from "./mobile.module.css";

const MobileMenu = ({ setIsOpen }) => {
  return (
    <div className={classes.mobile}>
      <div className={classes.closeIcon} onClick={() => setIsOpen(false)}>
        <i class="fas fa-times"></i>
      </div>
      <div className={classes.mobileOptions}>
        <div className={classes.mobileOption}>
          <a href="#experience">
            <span className={classes.optionIcon}>
              <i class="fas fa-briefcase"></i>
            </span>
            <span>Experience</span>
          </a>
        </div>
        <div className={classes.mobileOption}>
          <a href="#skills">
            <span className={classes.optionIcon}>
              <i class="fas fa-laptop"></i>
            </span>
            <span>Skills</span>
          </a>
        </div>
        <div className={classes.mobileOption}>
          <a href="#contact">
            <span className={classes.optionIcon}>
              <i class="far fa-envelope"></i>
            </span>
            <span>Contact</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
