import classes from "./contact.module.css";
import Separator from "../../common/separator";

const Contact = () => {
  return (
    <div className={classes.contact}>
      <Separator />
      <label className={classes.sectionTitle}>Contact</label>
      <div className={classes.contactContainer}>
        <div className={classes.contactLeft}>
          <p>Want to get in touch ?</p>
          <p>
            <i class="fas fa-phone"></i> 9738797627
          </p>
          <p>
            <i class="far fa-envelope"></i> debasish_pothal@hotmail.com
          </p>
        </div>
        <div className={classes.contactRight}>
          <div className={classes.download}>
            <a download href="">
              <i class="fas fa-file-download"></i> Download Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

// <a download>Download</a>
