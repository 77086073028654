import classes from "./footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.separator}></div>
      <p>
        <span className={classes.quote}>"</span>Your work is going to fill a
        large part of your life, and the only way to be truly satisfied is to do
        what you believe is great work. And the only way to do great work is to
        love what you do. If you haven't found it yet, keep looking. Don't
        settle. As with all matters of the heart, you'll know when you find it.
        <br />
        <br />
        <span className={classes.author}>- Steve Jobs</span>
      </p>
    </div>
  );
};

export default Footer;
