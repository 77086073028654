import classes from './skills.module.css';
import Separator from '../../common/separator';
import { skills } from '../../../data/skills';

const Skills = () => {
    return (
        <div className={classes.skills}>
            <Separator />
            <label className={classes.sectionTitle}>Skills</label>
            <div className={classes.skillsContainer}>
                {skills.map(skill => <div key={skill.name} className={classes.skillCard}><div className={classes.skillIcon}>{skill.icon}</div><label className={classes.skillLabel}>{skill.name}</label></div>)}
            </div>
        </div>
    )
}

export default Skills
