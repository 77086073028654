import classes from "./body.module.css";
import Experience from "./experience";
import Skills from "./skills";
import Contact from "./contact";

const Body = () => {
  return (
    <div className={classes.body}>
      <section id="experience">
        <Experience />
      </section>
      <section id="skills">
        <Skills />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
};

export default Body;
