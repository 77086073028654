import classes from "./experience.module.css";
import Separator from "../../common/separator";
import { experiences } from "../../../data/experiences";

const Card = ({ item }) => {
  return (
    <div className={classes.card}>
      <img src={item.companyLogo} className={classes.logo} alt={item.company} />
      <div className={classes.info}>
        <div className={classes.designationBrief}>
          <div>
            <label className={classes.companyName}>{item.company}</label>
            <div className={classes.dates}>
              <label>{item.startDate}</label> - <label>{item.endDate}</label>
            </div>
          </div>
          <div>{item.designation}</div>
        </div>
        <div className={classes.description}>
          {item.responsibilities.map((responsibility, index) => (
            <p key={`r-${index}`}>{responsibility}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

const Experience = () => {
  return (
    <div className={classes.experience}>
      <Separator />
      <label className={classes.sectionTitle}>Experience</label>
      <div className={classes.experienceList}>
        {experiences.map((experience, index) => (
          <Card key={`e-${index}`} item={experience} />
        ))}
      </div>
    </div>
  );
};

export default Experience;
