import Home from "./components/home"

const App = () => {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
